require('./accordions.js');
require('./appLinks.js');
require('./setDownloadButtonData.js');
require('./open_support.js');
require('./sidemenu.js');
require('./slider.js');
require('./enable_tracking.js');
require('./translates.js');
require('./images_src_by_key.js');
require('./handle_links.js');
require('./setViewport.js');
require('./handle_gtag_events.js');
// require('./appPanel.js'); Mobile App Banner is unused on home page
require('./setLocaleItem.js');

require('./popups/activate_forms.js');
require('./popups/couponClick.js');
require('./popups/customSelect.js');
require('./popups/forgotPass.js');
require('./popups/input.js');
require('./popups/toggle.js');
